import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "item-col justify-content-start d-flex" }
const _hoisted_2 = { class: "item-col justify-content-start d-flex" }
const _hoisted_3 = { class: "item-col justify-content-end d-flex" }
const _hoisted_4 = { class: "price-item d-flex flex-lg-column flex-row align-items-center align-items-lg-end align-self-center" }
const _hoisted_5 = { class: "price" }
const _hoisted_6 = { class: "item-col justify-content-end d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarItem = _resolveComponent("BarItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BarItem, { class: "item" }, {
      content: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-block d-lg-none title w-50" }, " Download ", -1)),
          _createElementVNode("span", {
            class: _normalizeClass({
                            'link': $props.invoice.downloadable === true,
                            'disabled': $props.invoice.downloadable === false,
                        }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.downloadInvoice($props.invoice)))
          }, _toDisplayString($props.invoice.no), 3)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "d-block d-lg-none title w-50" }, " Datum ", -1)),
          _createElementVNode("div", null, _toDisplayString($props.invoice.date), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "d-block d-lg-none title w-50" }, " Bedrag ", -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, " €" + _toDisplayString($setup.money().getMoneyString($props.invoice.amount)), 1),
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "d-block d-lg-none title w-50" }, " Status ", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["status-item d-flex align-items-center", $props.invoice.status.toLowerCase().replace(' ', '-')])
          }, [
            _createElementVNode("span", {
              class: _normalizeClass(["status", $props.invoice.status.toLowerCase().replace(' ', '-')])
            }, null, 2),
            _createTextVNode(" " + _toDisplayString($props.invoice.status), 1)
          ], 2)
        ])
      ]),
      _: 1
    })
  ]))
}