
import { saveAs } from 'file-saver';
import { useStore } from 'vuex';
import money from '@/helpers/moneyString';
import BarItem from '@/shared/components/BarItem.vue';
import { Invoice } from '@/shared/models/Invoice';

export default {
    props: {
        invoice: {
            type: Invoice,
        },
    },
    components: {
        BarItem,
    },
    setup() {
        const store = useStore();

        function downloadInvoice(invoice: Invoice) {
            if (invoice.downloadable) { 
                store.dispatch('DOWNLOAD_INVOICE', invoice.no).then((res) => {
                    const data = res.data;
                    const blob = new Blob([data], { type: 'application/pdf' });
                    saveAs(blob, `${invoice.no}.pdf`);
                });
            }
        }

        return {
            downloadInvoice,
            money,
        };
    },
};
