
import { plainToClass } from 'class-transformer';
import {
    computed, onMounted, ref,
} from 'vue';
import { useStore } from 'vuex';
import InvoiceItem from '@/components/InvoiceItem.vue';
import BarItem from '@/shared/components/BarItem.vue';
import TwoColPage from '@/shared/components/TwoColPage.vue';
import { Invoice } from '@/shared/models/Invoice';

export default {
    components: {
        InvoiceItem,
        TwoColPage,
        BarItem,
    },
    setup() {
        const store = useStore();
        const invoices = ref(Array<Invoice>());
        const getInvoices = async () => {
            await store.dispatch('GET_INVOICES').then((res) => {
                invoices.value = plainToClass(Invoice, res.data.invoices as Invoice[]);
            });
        };
        
        onMounted(getInvoices);

        const total = computed(() => {
            let amount = 0;
            invoices.value.filter((invoice: Invoice) => invoice.status.toLowerCase() === 'te betalen' || invoice.status.toLowerCase() === 'open').forEach((invoice: Invoice) => {
                amount += invoice.amount;
            });

            return amount.toFixed(2).toString().replace('.', ',');
        });

        function openContactUrl() {
            window.open('https://www.kwastwijnkopers.nl/contact/', '_blank');
        }

        return {
            invoices,
            total,
            openContactUrl,
        };
    },
};
