import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "invoices" }
const _hoisted_2 = { class: "invoices-list" }
const _hoisted_3 = { class: "d-flex justify-content-between align-items-center w-100" }
const _hoisted_4 = { class: "price-item d-flex flex-column align-items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BarItem = _resolveComponent("BarItem")!
  const _component_InvoiceItem = _resolveComponent("InvoiceItem")!
  const _component_TwoColPage = _resolveComponent("TwoColPage")!

  return (_openBlock(), _createBlock(_component_TwoColPage, {
    title: "Facturen",
    footer: "Heeft u vragen?",
    onFooterButtonClick: $setup.openContactUrl
  }, {
    main: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BarItem, {
            class: "mb-4 d-block mt-0",
            design: "no-margin"
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "total-item" }, " Totaal openstaand bedrag ", -1)),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", {
                    class: _normalizeClass(["price", $setup.total === '0,00' ? 'green' : 'orange'])
                  }, " €" + _toDisplayString($setup.total), 3),
                  _cache[0] || (_cache[0] = _createElementVNode("span", { class: "netto" }, " netto ", -1))
                ])
              ])
            ]),
            _: 1
          }),
          _cache[2] || (_cache[2] = _createElementVNode("ul", { class: "d-none d-lg-flex invoices-heading list-unstyled justify-content-between flex-grow-1 m-0" }, [
            _createElementVNode("li", { class: "text-start" }, " Download "),
            _createElementVNode("li", { class: "text-start" }, " Datum "),
            _createElementVNode("li", { class: "text-end" }, " Bedrag "),
            _createElementVNode("li", { class: "text-end" }, " Status ")
          ], -1)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.invoices, (invoice) => {
            return (_openBlock(), _createBlock(_component_InvoiceItem, {
              key: invoice.id,
              invoice: invoice
            }, null, 8, ["invoice"]))
          }), 128))
        ])
      ])
    ]),
    info: _withCtx(() => _cache[3] || (_cache[3] = [
      _createElementVNode("div", { class: "invoices-info d-none d-md-block" }, [
        _createElementVNode("h3", null, "Betaalgegevens"),
        _createElementVNode("ul", { class: "list-unstyled" }, [
          _createElementVNode("li", { class: "list-title" }, " Kwast Wijnkopers B.V. "),
          _createElementVNode("li", null, " NL13INGB0669464686 "),
          _createElementVNode("li", null, " BIC: INGBNL2A ")
        ]),
        _createElementVNode("p", null, "Vermeld altijd het factuurnummer in uw betalingsomschrijving.")
      ], -1)
    ])),
    _: 1
  }, 8, ["onFooterButtonClick"]))
}